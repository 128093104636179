<template>
  <div></div>
</template>

<script>
export default {
  name: "EventSpaces",
  // beforeMount(){
  //   const page = this.$store.state.epitch.pageTree.filter(page => page.id == 23)[0]
  //   this.$router.push(page.children[0].children[0].route)
  // }
};
</script>

<style>
</style>
